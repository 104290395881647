import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

const Test = () => {
  const [state, setState] = useState("Device");
  useEffect(() => {
    if (window) {
      console.log(window.deviceAPI || "nada");
      if (window.deviceAPI && window.deviceAPI.deviceName) {
        setState(window.deviceAPI.deviceName);
      }
    }
  }, []);

  return (
    <div className="App">
      <Helmet>
        <script src="https://theapicompany.com/deviceAPI.js?id=deviceAPI-59g4k9xt8b"></script>
      </Helmet>
      Device is {state}
    </div>
  );
};

export default Test;
